import "@hotwired/turbo-rails";
import * as bootstrap from "bootstrap";
import { ready } from "../src/helpers"
import { bindTimestamps } from "../src/application_methods";

window.bootstrap = bootstrap;

export const GRID_BREAKPOINTS = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
};

import "../controllers";

ready(() => {
    bindTimestamps();
})
