import { Controller } from "@hotwired/stimulus";
import { debounce } from "lodash";
import { fadeIn, fetchHeaders, swapClasses } from "../src/helpers";
import { Jodit } from "jodit/esm/index";
import "jodit/es2021/jodit.css";

enum SaveStatus {
  Success,
  Error,
  Pending,
  Disabled,
}

// Connects to data-controller="posts"
export default class extends Controller {
  static targets = ["form", "title", "content", "saveLabel", "submit"];

  declare readonly formTarget: HTMLFormElement;
  declare readonly titleTarget: HTMLInputElement;
  declare readonly contentTarget: HTMLInputElement;
  declare readonly saveLabelTarget: HTMLLabelElement;
  declare readonly submitTarget: HTMLButtonElement;

  private declare enableAutoSave: boolean;
  private declare url: string;

  declare debouncedSave: () => void;
  declare editor: Jodit;

  connect() {
    this.enableAutoSave =
      (this.element as HTMLDivElement).dataset.enableAutoSave === "true";

    this.url = this.formTarget.action;
    this.debouncedSave = debounce(this.patch, 1000);

    this.editor = Jodit.make(this.contentTarget, {
      saveHeightInStorage: true,
      theme: "dark",
      height: "auto",
      minHeight: "50vh",
    });

    if (this.enableAutoSave) {
      this.editor.e.on("change", () => {
        this.save();
      });
    } else {
      this.setSaveStatus(SaveStatus.Disabled);
    }

    fadeIn(this.formTarget, document.getElementById("spinner")!);
  }

  // Entrypoint for saving the post
  save() {
    if (!this.enableAutoSave) return;

    this.setSaveStatus(SaveStatus.Pending);
    this.debouncedSave();
  }

  patch() {
    fetch(this.url, {
      method: "PATCH",
      headers: fetchHeaders(),
      body: JSON.stringify({
        post: {
          title: this.titleTarget.value,
          content: this.editor.value,
        },
        save: true,
      }),
    })
      .then(() => {
        this.setSaveStatus(SaveStatus.Success);
      })
      .catch((e) => {
        console.log(e);
        this.setSaveStatus(SaveStatus.Error);
      })
      .finally(() => {
        this.submitTarget.disabled = false;
      });
  }

  private setSaveStatus(state: SaveStatus) {
    this.submitTarget.disabled = false;

    let iconElem = this.saveLabelTarget.querySelector(
      ":scope i"
    ) as HTMLElement;
    let textElem = this.saveLabelTarget.querySelector(
      ":scope span"
    ) as HTMLElement;

    switch (state) {
      case SaveStatus.Success:
        swapClasses(iconElem, {
          add: "fa-check",
          remove: ["fa-x", "spinner-border"],
        });

        swapClasses(iconElem, {
          add: "text-success-emphasis",
          remove: ["text-danger-emphasis", "text-warning-emphasis"],
        });

        textElem.textContent = "Saved";
        break;
      case SaveStatus.Error:
        swapClasses(iconElem, {
          add: "fa-x",
          remove: ["fa-check", "spinner-border"],
        });

        swapClasses(iconElem, {
          add: "text-danger-emphasis",
          remove: ["text-success-emphasis", "text-warning-emphasis"],
        });

        textElem.textContent = "Save failed";
        break;
      case SaveStatus.Pending:
        swapClasses(iconElem, {
          add: ["spinner-border", "spinner-border-sm"],
          remove: ["fa-check", "fa-x"],
        });

        swapClasses(iconElem, {
          add: "text-warning-emphasis",
          remove: ["text-success-emphasis", "text-danger-emphasis"],
        });

        textElem.textContent = "Saving...";
        this.submitTarget.disabled = true;
        break;
      case SaveStatus.Disabled:
        swapClasses(iconElem, {
          add: "fa-ban",
          remove: ["fa-check"],
        });

        swapClasses(iconElem, {
          add: "text-secondary-emphasis",
          remove: ["text-success-emphasis"],
        });

        textElem.textContent = "Autosave disabled";
        break;
    }
  }
}
