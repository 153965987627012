import moment, { Moment } from "moment";
import * as bootstrap from "bootstrap";

export function bindTimestamps() {
  let elements = document.querySelectorAll(".timestamp");

  elements.forEach((elem) => {
    let element = elem as HTMLElement;

    let dataset = element.dataset;
    let timestamp: Moment | string = moment(dataset.timestamp);

    // Regardless of format, set the full timestamp as a tooltip
    element.dataset.bsTitle = timestamp.toString();

    if (dataset.format) {
      timestamp = timestamp.format(dataset.format.trim());
    }

    element.innerHTML = timestamp.toString();

    const tooltip = new bootstrap.Tooltip(element);
  });
}
